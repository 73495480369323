export const styles = {
  containerMobile: {
    width: '100%',
    maxWidth: '600px',
    padding: '25px',
    paddingBottom: '80px',
  },
  containerDesktop: {
    width: '40%',
    padding: 0,
    alignItems: 'center',
    margin: 'auto',
  },
};

export const getContainerStyles = isDesktop => (isDesktop ? styles.containerDesktop : styles.containerMobile);
