const getBaseUrl = hostName => {
  switch (true) {
    case hostName === 'checkout.getinshape.no':
      return 'https://api.getinshape.no';
    case hostName.includes('dev'):
      return 'https://dev-api.getinshape.no';
    case hostName.includes('stage'):
      return 'https://stage-api.getinshape.no';
    default:
      // return 'https://dev-api.getinshape.no';
      return 'http://localhost:80';
  }
};

const getStripePublicKey = hostName => {
  switch (true) {
    case hostName === 'checkout.getinshape.no':
    case hostName.includes('stage'):
      // eslint-disable-next-line max-len
      return 'pk_live_51LQBzNLbET46sSBAV7yqlIxsbvyyPkwJozjaN8V761IXNF3RlCiwn1IDhMvyrhtx0Y8INAfgc2a5oRWA1T0CGzh000LZtvOwYI';
    case hostName.includes('dev'):
      // eslint-disable-next-line max-len
      return 'pk_test_51LQBzNLbET46sSBAZBGtxNbqC8M80YfBFoMm7sMMv603gXO8Yr8rn2GHhVKLfzz9GHDYCrW6RT6Ws8FOmsLC2fz8001zxBmYnr';
    default:
      // eslint-disable-next-line max-len
      return 'pk_test_51LQBzNLbET46sSBAZBGtxNbqC8M80YfBFoMm7sMMv603gXO8Yr8rn2GHhVKLfzz9GHDYCrW6RT6Ws8FOmsLC2fz8001zxBmYnr';
  }
};

const getStripeRedirectUrl = hostName => {
  switch (true) {
    case hostName === 'checkout.getinshape.no':
      return 'https://checkout.getinshape.no/subscriptions';
    case hostName.includes('dev'):
      return 'https://dev-checkout.getinshape.no/subscriptions';
    case hostName.includes('stage'):
      return 'https://stage-checkout.getinshape.no/subscriptions';
    default:
      return 'http://localhost:3001/subscriptions';
  }
};

const getGoogleTagManagerId = hostname => {
  switch (true) {
    case hostname === 'checkout.getinshape.no':
      return 'GTM-NDTXVJCM';
    case hostname.includes('stage'):
      return 'GTM-XXstage';
    case hostname.includes('dev'):
      return 'GTM-XXdevelopment';
    default:
      return 'GTM-XXlocal';
  }
};

const getGoogleTagServerUrl = hostname => {
  switch (true) {
    case hostname === 'checkout.getinshape.no':
      return 'https://tagging.getinshape.no';
    case hostname.includes('dev'):
      return 'https://tagging.getinshape.no';
    default:
      return undefined;
  }
};

const getSubscriptionRedirectIds = hostName => (
  hostName === 'checkout.getinshape.no'
    ? {
      2: 13, // App Access
      21: 22, // Getinshape
      3: 5, // Getinshape w/coaching 4 weeks
      4: 6, // Getinshape w/coaching 12 weeks
    } : {
      9: 20, // App Access
      21: 22, // Getinshape
      10: 13, // Getinshape w/coaching 4 weeks
      11: 14, // Getinshape w/coaching 12 weeks
    });

export const STRIPE_PUBLIC_KEY = getStripePublicKey(window.location.host);
export const STRIPE_REDIRECT_URL = getStripeRedirectUrl(window.location.host);
export const GOOGLE_TAG_MANAGER_ID = getGoogleTagManagerId(window.location.host);
export const GOOGLE_TAG_SERVER_URL = getGoogleTagServerUrl(window.location.host);
export const GOOGLE_TAG_JS_FILE_NAME = 'tmvytlmh';

export const BASE_URL = getBaseUrl(window.location.host);

export const SUBSCRIPTION_REDIRECT_IDS = getSubscriptionRedirectIds(window.location.host);

export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const TABLET_WIDTH = 1023;
export const MOBILE_WIDTH = 501;
export const SLIDES_PER_ROW_LIMIT = 4;

export const FACEBOOK_APP_ID = 660803152028593;
export const GOOGLE_APP_ID = '1068917971454-jmttcpfb6bv3qtkgldec8u7nlkcn65p1.apps.googleusercontent.com';
export const APPLE_APP_ID = 'no.getinshape.payments';
export const PAYMENT_SUCCEEDED = 'succeeded';
export const ORDER_UPDATED = 'Updated';
export const SUBSCRIPTION_DOWNGRADED = 'Downgraded';
export const APPLE_REDIRECT_URI = `https://${window.location.host}`;
export const TERMS_AND_CONDITIONS_LINK = 'https://getinshape.no/betingelser';
export const PRIVACY_POLICY_LINK = 'https://getinshape.no/personvern';
export const APP_STORE_LINK = 'https://apps.apple.com/us/app/getinshape-fitness-app/id1586470002';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.elitept';
export const SALES_CONDITIONS_PRIVACY_LINK = 'https://getinshape.no/betingelser';

export const PRODUCT_TYPES = {
  booklet: 'booklet',
  document: 'document',
  dietPlan: 'diet_plan',
  trainingProgram: 'training_program',
};

export const STRIPE_PRODUCT_TYPES = {
  CONSUMABLE: 'Consumable',
  NON_CONSUMABLE: 'Non-Consumable',
  SUBSCRIPTION: 'Subscription',
};

export const UTM_PARAMS = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',
  CONTENT: 'utm_content',
  ID: 'utm_id',
  TERMS: 'utm_terms',
};

export const TRACKING_PRODUCT_TYPES = {
  subscription: 'subscription',
  product: 'product',
};
