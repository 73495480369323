import React, { useEffect, useState } from 'react';
import { routes } from '@utils';
import { useTranslation } from 'react-i18next';
import { CheckoutBlock, Flex, GoBackButton } from '@components';
import { useWindowSize } from '@hooks/useWindowSize';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { STORE_NAMES } from '@constants';
import { getSubscription } from '../../utils/helpers';
import { getAllSubscriptions } from '../../store/products/duck';
import { styles } from './styles';

const Checkout = () => {
  const { t } = useTranslation();

  const {
    availableProducts: {
      subscriptions,
    },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const dispatch = useDispatch();

  const { id } = useParams();
  const history = useHistory();

  const [selectedSubscription, setSelectedSubscription] = useState({});

  const { isDesktop } = useWindowSize();

  useEffect(async () => {
    if (subscriptions.length === 0) {
      await dispatch(getAllSubscriptions());
    } else {
      const foundSubscription = getSubscription(id, subscriptions);

      if (!foundSubscription) {
        history.push(routes.notFound);
      }

      setSelectedSubscription(foundSubscription);
    }
  }, [subscriptions]);

  return (
    <Flex height="100%" width="100%" column itemsCenter>
      {!isDesktop && (
        <GoBackButton styles={styles.goBackButton} title={t('SubscriptionsInfo.Checkout')} />
      )}
      <CheckoutBlock subscription={selectedSubscription} />
    </Flex>
  );
};

export default Checkout;
